import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mobile-menu-drawer"
export default class extends Controller {
  static targets = ["toggleButton"];

  connect() {
    // pulling value manually because menu & toggleButton are in different scopes
    // i.e. menu is in <main>, toggleButton is in <header>
    this.menuDrawerObj = document.getElementById('mobile-menu-drawer');
  }

  toggle() {
    this.toggleButtonTargets.forEach(element => {
      element.classList.toggle('tw-hidden');
    });
    this.menuDrawerObj.classList.toggle('tw-hidden');
  }
}
